import styled from 'styled-components';

export const Container = styled.header`
  height: 5rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  background-color: #fff;
`;

export const Content = styled.div`
  width: 60rem;
  max-width: 100vw;
  height: 100%;
  padding: 1rem 2rem;
  margin: 0 auto;
`;

export const Logo = styled.img`
  height: 100%;
`;
